import React from "react";
import { PopupModal } from "react-calendly";
import bookNow from '../Content/book-now.png';
 
class BookNow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div>
              <button
                className='btnBook'
                title="Book a virtual coffee with me today!"
                onClick={() => this.setState({ isOpen: true })}
              >
                <img src={bookNow} alt="book now" />
              </button>
              <PopupModal
                url="https://calendly.com/tyra-lee"
                pageSettings={this.props.pageSettings}
                utm={this.props.utm}
                prefill={this.props.prefill}
                onModalClose={() => this.setState({ isOpen: false })}
                open={this.state.isOpen}
                rootElement={document.getElementById("root")}
              />
            </div>
          );
    }
}
 
export default BookNow;