import React from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import logo from "../Content/tyra-lee-logo.jpeg";
import HomeIcon from "../Icons/home-icon";

function Legal() {
  const topRef = useRef(null);
  useEffect(() => {
    document.title = 'Tyra-Lee - Terms and Conditions';
  }, []);
  return (
    <div className="terms-wrapper">
      <div className="home-section">
        <h1>Disclaimer:</h1>
        <p>
          Please read this disclaimer carefully before using Tyra-Lee's website
          or engaging in any services offered.
        </p>
        <p>
          <strong>1. Scope of Services:</strong> Tyra-Lee offers services as a
          development coach, life coach, master NLP practitioner,
          hypnotherapist, naturopathic practitioner, and somatic movement coach.
          These services are intended to support personal growth, well-being,
          and holistic health. It's important to note that Tyra-Lee is not a
          licensed medical or mental health professional. While hypnotherapy,
          NLP techniques, naturopathic practices, and somatic movement can
          complement traditional healthcare, they are not substitutes for
          medical or psychiatric treatment. Clients should consult with
          qualified healthcare professionals for any medical or psychological
          concerns.
        </p>
        <p>
          <strong>2. Individual Results:</strong> Results from coaching,
          hypnotherapy, NLP, naturopathy, and somatic movement sessions may vary
          from person to person. While Tyra-Lee strives to provide effective
          guidance and support, individual outcomes cannot be guaranteed.
          Clients are encouraged to actively participate in the process and to
          communicate openly about their experiences and progress.
        </p>
        <p>
          <strong>3. Informed Consent:</strong> Clients engage in coaching,
          hypnotherapy, NLP, naturopathy, and somatic movement sessions
          voluntarily and with full understanding of the services provided.
          Prior to beginning any session, Tyra-Lee will obtain informed consent,
          explaining the nature of the services, potential benefits, and any
          risks involved. Clients are encouraged to ask questions and express
          any concerns before proceeding with sessions.
        </p>
        <p>
          <strong>4. Confidentiality:</strong> Tyra-Lee is committed to
          maintaining the confidentiality of client information. All personal
          and sensitive information shared during sessions will be kept strictly
          confidential, except in cases where disclosure is required by law or
          to prevent harm to oneself or others.
        </p>
        <p>
          <strong>5. Professional Boundaries:</strong> Clients and Tyra-Lee will
          maintain professional boundaries throughout the coaching and therapy
          process. This includes respecting each other's time, maintaining
          appropriate communication channels, and refraining from any behaviour
          that could compromise the professional relationship.
        </p>
        <p>
          <strong>6. Liability Limitations:</strong> While Tyra-Lee endeavours
          to provide accurate and helpful information, no liability is assumed
          for the use of information provided on this website or during
          coaching, hypnotherapy, NLP, naturopathy, and somatic movement
          sessions. Clients assume full responsibility for their decisions and
          actions taken based on the information and guidance received.
        </p>
        <p>
          By accessing this website or engaging in services with Tyra-Lee, you
          acknowledge that you have read, understood, and agree to the terms of
          this disclaimer.
        </p>
        <h1>Website Terms & Conditions:</h1>
        <h2>Website Terms and Conditions for Tyra-lee.com</h2>
        <p>
          Welcome to Tyra-lee.com. These terms and conditions outline the rules
          and regulations for the use of our website. By accessing this website,
          we assume you accept these terms and conditions. Do not continue to
          use Tyra-lee.com if you do not agree to all of the terms and
          conditions stated on this page.
        </p>
        <p>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement, and Disclaimer Notice and any or all Agreements:
          "Client," "You," and "Your" refer to you, the person accessing this
          website and accepting the Company's terms and conditions. "The
          Company," "Ourselves," "We," "Our," and "Us" refer to our Company.
          "Party," "Parties," or "Us" refers to both the Client and ourselves,
          or either the Client or ourselves. All terms refer to the offer,
          acceptance, and consideration of payment necessary to undertake the
          process of our assistance to the Client in the most appropriate manner
          for the express purpose of meeting the Client's needs in respect to
          the provision of the Company's stated services, in accordance with and
          subject to, prevailing law of the United States. Any use of the above
          terminology or other words in the singular, plural, capitalization,
          and/or he/she or they, are taken as interchangeable and therefore as
          referring to the same.
        </p>
        <h3>Cookies:</h3>
        <p>
          We employ the use of cookies. By accessing Tyra-lee.com, you agreed to
          use cookies in agreement with the Tyra-lee.com's Privacy Policy.
        </p>
        <h3>Licence:</h3>
        <p>
          Unless otherwise stated, Tyra-lee.com and/or its licensors own the
          intellectual property rights for all material on Tyra-lee.com. All
          intellectual property rights are reserved. You may access this from
          Tyra-lee.com for your own personal use subjected to restrictions set
          in these terms and conditions.
        </p>
        <p>
          <strong>You must not:</strong>
        </p>
        Republish material from Tyra-lee.com
        <br />
        Sell, rent or sub-license material from Tyra-lee.com
        <br />
        Reproduce, duplicate or copy material from Tyra-lee.com
        <br />
        Redistribute content from Tyra-lee.com
        <br />
        <p>This Agreement shall begin on the date hereof.</p>
        <h3>Hyperlinking to our Content:</h3>
        <p>
          The following organizations may link to our Website without prior
          written approval:
        </p>
        Government agencies;
        <br />
        Search engines;
        <br />
        News organizations;
        <br />
        <p>
          Online directory distributors may link to our Website in the same
          manner as they hyperlink to the Websites of other listed businesses;
          and System wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our Website.
        </p>
        <h3>Reservation of Rights:</h3>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amend
          these terms and conditions and it's linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>
        <h3>Removal of links from our website:</h3>
        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>
        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>
        <h3>Disclaimer:</h3>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties, and conditions relating to our website
          and the use of this website. Nothing in this disclaimer will:
        </p>
        limit or exclude our or your liability for death or personal injury;
        <br />
        limit or exclude our or your liability for fraud or fraudulent
        misrepresentation;
        <br />
        limit any of our or your liabilities in any way that is not permitted
        under applicable law; or
        <br />
        exclude any of our or your liabilities that may not be excluded under
        applicable law.
        <br />
        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>
        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
        <p>
          <Link
            to="/"
            title="Home"
            onClick={() =>
              window.scrollTo({
                top: topRef.current.offsetTop,
                behavior: "instant",
              })
            }
          >
           <HomeIcon></HomeIcon>&nbsp;Return to Home page
          </Link>
        </p>
      </div>
      <div className="terms-logo">
        <Link
          to="/"
          title="Home"
          onClick={() =>
            window.scrollTo({
              top: topRef.current.offsetTop,
              behavior: "instant",
            })
          }
        >
          <img src={logo} alt="logo" className="masthead-logo" />
        </Link>
      </div>
    </div>
  );
}

export default Legal;
