import React from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import logo from "../Content/tyra-lee-logo.jpeg";
import mastheadImage from "../Content/Tyra-30.jpg";
import getInTouch from "../Content/Tyra-3.jpg";
import Accordion from "react-bootstrap/Accordion";
import BookNow from "../Shared/bookNow";
import ArrowDownIcon from "../Icons/arrow-down-icon";
import FacebookIcon from "../Icons/facebook-icon";
import InstagramIcon from "../Icons/instagram-icon";
import SpotifyIcon from "../Icons/spotify-icon";
import YoutTubeIcon from "../Icons/youtube-icon";
import LinkedinIcon from "../Icons/linkedin-icon"

function Home() {

    useEffect(() => {
        document.title = 'Development coach, Tyra-Lee';
      }, []);

    const paragraphRef = useRef(null);
    const topRef = useRef(null);
    const accordionRef = useRef(null);



  return (
    <div className="page-container" ref={topRef}>
      <header className="masthead">
        <div>
          <img src={logo} alt="logo" className="masthead-logo" />
          <p className="masthead-intro">
            It does not matter how you came to be here, <br />
            It only matters that you desire to grow...
          </p>
          <div className="btn-scroll-container">
            <button
              className="btn-scroll-mobile animate-scroll"
              onClick={() =>
                window.scrollTo({
                  top: paragraphRef.current.offsetTop,
                  behavior: "smooth",
                })
              }
            >
              <ArrowDownIcon></ArrowDownIcon>
            </button>
          </div>
        </div>
        <div>
          <button
            className="btn-scroll animate-scroll"
            onClick={() =>
              window.scrollTo({
                top: paragraphRef.current.offsetTop,
                behavior: "smooth",
              })
            }
          >
            <ArrowDownIcon></ArrowDownIcon>
          </button>
          <img
            src={mastheadImage}
            alt="Tyra-Lee"
            className="masthead-portrait"
          />
        </div>
      </header>
      <div className="home-section" ref={paragraphRef}>
        <h1>
          Where are you <br />
          on the timeline?
        </h1>

        <Accordion flush  ref={accordionRef}>
          <Accordion.Item eventKey="0" onClick={() =>
                window.scrollTo({
                  top: accordionRef.current.offsetTop,
                  behavior: "smooth",
                })
              }>
            <Accordion.Header>
              <h2>Early Years 0 - 18 Years Old</h2>
            </Accordion.Header >
            <Accordion.Body>
              <Accordion flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Early developmental challenges including toilet training,
                      speech, quality of sleep and eating habits
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Firstly, it is important to remember that your child's
                      challenges are not a reflection of their worth or your
                      parenting. They are simply part of the journey to gaining
                      understanding and confidence for a happier, healthier
                      life.
                    </p>
                    <p>
                      I like to view these challenges as an opportunity for us
                      to create a deeper sense of security and ability to
                      communicate productively in the child from a young age.
                    </p>
                    <p>
                      Working as a team assisted by techniques such as Parent
                      Coaching, Naturopathy and The Goulding Method&reg; of
                      SleepTalk we are able to address challenges such as toilet
                      training, speech, quality of sleep and eating habits.
                    </p>
                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Emotional intelligence development, building resilience
                      and managing anger
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      As parents, we understand the significance of creating a
                      loving and supportive environment filled with
                      unconditional love. This is where our children develop
                      vital coping strategies, problem-solving skills, and a
                      positive outlook on life.
                    </p>
                    <p>
                      One essential aspect of nurturing resilience is arming our
                      children with emotional intelligence. It's a gift that
                      empowers them to manage their emotions, including anger,
                      constructively. Resilience teaches that it is perfectly
                      normal to experience anger; what truly matters is how they
                      respond to it.
                    </p>
                    <p>
                      Together, using techniques such as Parent Coaching,
                      Somatic Movement, Naturopathy and The Goulding Method&reg;
                      of SleepTalk we are able to guide our children on a path
                      to handling anger positively and constructively, embracing
                      their resilience and emotional intelligence as a whole.
                    </p>
                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Feeling left out or left behind
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      If this was ever something you experienced as the majority
                      of us have, at some point in our lives, it is most
                      certainly not something you'd ever like to witness your
                      child having to endure either.
                    </p>
                    <p>
                      That sting of exclusion that some of us still haven't
                      processed, hiding in their welled-up eyes as they try not
                      to take it on personally. I see you, I know your pain, as
                      you know theirs. You might be torn between showering them
                      with love and teaching them that it's just the way cookie
                      crumbles, chin up?
                    </p>
                    <p>
                      Together, we can work toward providing a safe and
                      understanding space for them to express their feelings and
                      concerns. Using techniques such as Parent Coaching,
                      Naturopathy and The Goulding Method&reg; of SleepTalk we
                      are able to find healthy ways to address and overcome
                      these feelings and build a stronger sense of self.
                    </p>
                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Beginning school, separation, anxiety, transitioning or
                      moving and managing change
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Embarking on a new journey, whether it's starting school
                      or facing any significant change, is a pivotal moment in
                      our children's lives. It's a time that sparks a mix of
                      enthusiasm and apprehension. This transitional phase
                      presents them with the opportunity to embark on
                      adventures, form new friendships, and acquire vital life
                      skills. Our role as caregivers is to cultivate and nurture
                      their enthusiasm, enabling them to construct unwavering
                      confidence.
                    </p>
                    <p>
                      Each child navigates transitions such as this at their own
                      pace, some with ease and others requiring more time.
                      Nevertheless, our duty remains steadfast: we provide a
                      loving, supportive environment that encourages them to
                      articulate their feelings and inquiries.
                    </p>
                    <p>
                      By working together and employing a range of techniques,
                      such as Parent Coaching, Life Readiness Coaching,
                      Naturopathy, Somatic Movement and The Goulding Method&reg;
                      of SleepTalk we can offer valuable support to our children
                      and families during periods of separation, transitions,
                      relocation, or any major changes they encounter. This
                      collaborative approach ensures that we can address
                      challenges with a problem-solving mindset, guiding them
                      through each step of their evolving journey.
                    </p>
                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Neurodevelopmental and behavioural challenges including:
                      ASD, OCD, ODD, ADD, ADHD
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Your child is a remarkable human being with incredible
                      potential and you are doing an admirable job navigating
                      this journey. It may not feel like it but your patience
                      and dedication are making a lasting difference in their
                      lives.
                    </p>
                    <p>
                      At times their behaviour may be challenging, but it's
                      important to understand that these behaviours serve a
                      purpose for them. Our task is to uncover the underlying
                      reasons behind these actions and guide them toward
                      healthier ways of expressing themselves and discovering
                      their capabilities.
                    </p>
                    <p>
                      This journey is all about learning and growth and our
                      approach is rooted in empathy, patience, and empowerment.
                      Together with techniques such as Parent Coaching,
                      Naturopathy and The Goulding Method&reg; of SleepTalk, we
                      can create, restore and maintain a sense of calm so they
                      can achieve their goals and lead fulfilling lives.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Bed wetting, nail biting and other habits
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Dealing with habits like bed-wetting, nail-biting, and
                      other persistent behaviours in our children's lives is a
                      significant journey that requires a careful blend of
                      understanding and action. This phase may evoke mixed
                      emotions, ranging from concern to determination, no matter
                      where you're at it can all feel a little overwhelming.
                    </p>
                    <p>
                      Each child is unique in their journey to break these
                      habits. Some may make progress swiftly, while others might
                      need more time and support. Nevertheless, our role as
                      caregivers remains constant: we aim to provide a nurturing
                      and empathetic environment where our children can openly
                      discuss their struggles and seek guidance.
                    </p>
                    <p>
                      Together, utilising techniques like Parent Coaching, Life
                      Readiness Coaching, Naturopathy and The Goulding
                      Method&reg; of SleepTalk, we can effectively support them
                      in overcoming these challenges, ensuring that our children
                      can successfully navigate the path to healthier and more
                      constructive habits.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Temper tantrums, sibling rivalry and other behaviours
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We understand how tough it is dealing with any of these
                      challenges. You are here because you've tried almost
                      everything and you are exhausted, questioning your
                      capabilities as a parent. You're doing an amazing job, all
                      of these behaviours are a form of communication and I
                      commend you for finding ways to understand them.
                    </p>
                    <p>
                      As parents, our job is to help them learn to communicate
                      in healthy, productive ways. Creating a safe and loving
                      environment for them to express their needs is key. We can
                      focus on strategies to help them manage their emotions and
                      confidence, reducing anxiety.
                    </p>
                    <p>
                      Together, Using Parent Coaching, Somatic Movement,
                      Naturopathy and The Goulding Method&reg; of SleepTalk we
                      can help them build stronger family bonds and essential
                      skills to better navigate these challenges.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Negative thoughts and behaviours
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      When it comes to your child's struggles with negative
                      thoughts and behaviours, it's essential to acknowledge the
                      challenges they're facing on their journey towards
                      personal growth and happiness. I see how these negative
                      thoughts, like self-doubt and self-criticism, can hold
                      them back from reaching their full potential and
                      experiencing true well-being.
                    </p>
                    <p>
                      I know it might seem daunting to imagine your child
                      breaking free from these patterns, but I truly believe in
                      their ability to overcome these obstacles. With the right
                      support and guidance, they can learn how to transform
                      their thought patterns and behaviours for the better.
                    </p>
                    <p>
                      Through approaches like Parent Coaching, Life Readiness
                      Coaching, Somatic Movement, The Goulding Method&reg; of
                      SleepTalk and Hypnosis, we have the tools and strategies
                      to help your child reframe their thinking and cultivate a
                      more positive mindset. Together, we can empower them to
                      navigate through life with confidence and resilience.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Making friends and forming social bonds
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We know that seeing our children embark on the journey of
                      making new friends can stir up a whirlwind of emotions.
                      It's both exciting and nerve-wracking, isn't it? These
                      mixed feelings are entirely natural, a sign of growth, and
                      a willingness to embrace new experiences.
                    </p>
                    <p>
                      We understand that forming social bonds is a vital part of
                      growing up, and it's a unique journey for every child and
                      adolescent. Some will dive right into it, while others may
                      take their time, and both approaches are absolutely valid.
                      As understanding parents, our role is to be their guiding
                      light on this path. We can encourage them to be their
                      authentic selves, cherish their interests, and find common
                      ground with others. Teaching them good communication,
                      empathy, and kindness will help them nurture positive and
                      meaningful friendships.
                    </p>
                    <p>
                      Together, using techniques like Parent Coaching, Life
                      Readiness Coaching, Naturopathy, Hypnosis and The Goulding
                      Method&reg; of SleepTalk, we'll help your children
                      flourish in the world of friendships.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Academic confidence, performance enhancement and esteem
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      As parents who deeply care about our children's growth, we
                      should reassure ourselves that challenges are a natural
                      part of this journey, offering valuable opportunities for
                      growth and resilience. Our role is to create a warm and
                      inviting learning environment where curiosity thrives.
                    </p>
                    <p>
                      Always remember, the learning path is a lifelong adventure
                      that extends well beyond the classroom or sports field.
                      It's about uncovering passions, delving into new ideas,
                      and building the skills needed to navigate life's
                      challenges. With our unwavering guidance and support, our
                      children and adolescents can embark on this thrilling
                      journey with enthusiasm and wonder.
                    </p>
                    <p>
                      Together using techniques like Parent Coaching, Life
                      Readiness Coaching, Naturopathy, Hypnosis and The Goulding
                      Method&reg; of SleepTalk, we can help your child set
                      realistic goals, develop a growth mindset, and acquire the
                      skills and knowledge needed to excel. Whether it's
                      academics, sports, or creative endeavours, we, like you,
                      believe in your child's potential to achieve their best.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Stress, anxiety and fear
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      It's achingly overwhelming when our children experience
                      these emotions, exposing our own feelings of helplessness.
                      We understand that stress, fear and anxiety are part of
                      the human experience, but it's difficult to navigate these
                      when we ourselves feel triggered.
                    </p>
                    <p>
                      Our role is to create a safe and comforting space where
                      our children can freely discuss their fears and anxieties.
                      Teaching them healthy coping mechanisms with a core belief
                      of trust in themselves and secure communication will
                      empower them to manage their feelings effectively. Our
                      goal is to equip them with the tools they need to
                      prioritise their safety and well-being as well as letting
                      them know it's okay to seek help when needed.
                    </p>
                    <p>
                      With support and understanding using techniques like
                      Parent Coaching, Life Readiness Coaching, Naturopathy,
                      Hypnosis and The Goulding Method&reg; of SleepTalk, we can
                      equip your children with the skills and confidence they
                      need to better manage their response and reactions to
                      their impacting environment, building resilience along the
                      way.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Puberty and physical changes
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We get it. Puberty can be quite the rollercoaster, for our
                      kids and us. But let's remember, these changes are a
                      completely normal part of their journey into adulthood,
                      being able to understand these changes on a deeper level
                      and validate what are perfectly normal bodily functions,
                      ensures our children have the confidence they need to live
                      in their own bodies.
                    </p>
                    <p>
                      Our job is to create a safe space where our children can
                      openly explore and discuss these shifts, assuring them
                      that what they're going through isn't just normal—it's a
                      beautiful transformation. So, instead of treating puberty
                      like a problem, let's embrace it as a remarkable journey.
                      It's a chance for our children to discover themselves and
                      grow personally, learning confidence and self-acceptance.
                      Autonomy, privacy, acceptance, open communication and
                      reassurance are our best allies in guiding them through
                      this transformation.
                    </p>
                    <p>
                      Using techniques like Parent Coaching, Life Readiness
                      Coaching, Naturopathy, Hypnosis and The Goulding
                      Method&reg; of SleepTalk, they'll navigate these changes
                      gracefully, laying the foundation for a healthy and
                      confident self image.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Understanding and navigating early romantic relationships
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      As parents, we understand that early romantic
                      relationships can stir up a mix of emotions and concerns.
                      It's only natural to worry about our children as they
                      venture into this new phase of their lives. However, let's
                      reframe our perspective and recognise these relationships
                      as crucial stepping stones in their personal growth.
                    </p>
                    <p>
                      Our role is to provide a safe and open space where our
                      children can freely discuss their feelings and
                      experiences. Navigating these relationships can indeed be
                      a learning curve, so it's essential to teach them about
                      healthy communication, setting boundaries, and the
                      importance of consent. Instilling values like mutual
                      respect, kindness, and empathy is fundamental in any
                      relationship they choose to explore. These experiences
                      offer unique opportunities for self-discovery and
                      resilience-building. Encouraging our children to reflect
                      on what they value in themselves and a partner, will
                      empower them to make thoughtful choices.
                    </p>
                    <p>
                      If you're feeling overwhelmed or unsure about how to best
                      support your child during this phase, working with someone
                      like me can provide valuable tools and insights to ensure
                      both you and your child develop the necessary skills to
                      build trust and nurture open communication. Together using
                      techniques like Parent Coaching, Relationship Coaching,
                      Life Readiness Coaching and Hypnosis, we can help them
                      flourish in their relationships and personal development.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Divorce or separation, co-parenting and single parenting,
                      introducing your child to a new partner
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Any of these challenges will bring forth a wide range of
                      emotions which demand a delicate balance of understanding
                      and empathy. Our role as parents remains steadfast: we aim
                      to create a stable and supportive environment where our
                      children can express their feelings, ask questions, and
                      adapt to the evolving family dynamics.
                    </p>
                    <p>
                      By addressing these topics with consistent, proactive
                      support, we can guide our children through these
                      significant life changes, helping them grow into resilient
                      individuals who can adapt to new family dynamics and
                      relationships with confidence and positivity.
                    </p>
                    <p>
                      Using collaborative techniques like Parent Coaching, Life
                      Readiness Coaching, Somatic Movement and The Goulding
                      Method&reg; of SleepTalk, we can foster a nurturing and
                      structured environment, successfully reinforcing
                      unconditional love and support through these challenges.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Developing independence, body autonomy, gender and
                      identity
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Watching our children grow and embrace independence can be
                      both thrilling and worrisome. It's a fundamental part of
                      their journey into adulthood, even though it may appear
                      challenging at times. But let's reframe this phase as a
                      remarkable opportunity for them to uncover their
                      strengths, make choices, and take ownership of their
                      actions.
                    </p>
                    <p>
                      As parents, our role is to be there with guidance and
                      unwavering support as they navigate this path. We can
                      encourage them to make age-appropriate decisions, shoulder
                      responsibilities, and draw wisdom from their experiences.
                      These steps contribute to the development of
                      self-confidence and resilience, qualities that will serve
                      them exceptionally well in life. We can impart crucial
                      life skills, such as problem-solving, decision-making, and
                      effective communication skills, that empower them to excel
                      in developing independence, autonomy and identity.
                    </p>
                    <p>
                      Together using techniques like Parent Coaching, Life
                      Readiness Coaching, Hypnosis and The Goulding Method&reg;
                      of SleepTalk we will provide steadfast support, enabling
                      them to cultivate the self-assurance and self-reliance
                      required to become capable and responsible individuals.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Death, loss, and grief
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Death, loss, and grief are universal experiences that
                      touch all of our lives. These emotions can be tough to
                      handle, but they also highlight the depth of our love and
                      connections with others. Instead of seeing them solely as
                      sources of sadness, let's reframe them as a natural part
                      of our humanity.
                    </p>
                    <p>
                      Explaining death, loss and grief with age appropriate
                      language and an understanding of our children's unique
                      emotional state, presents us with opportunities to guide
                      our children into developing empathy and gives them the
                      resilience to process various losses in a healthy and
                      appropriate way.
                    </p>
                    <p>
                      By acknowledging these emotions and supporting one another
                      whilst using techniques like Parent Coaching, Life
                      Readiness Coaching, Somatic Movement andThe Goulding
                      Method&reg; of SleepTalk our children can learn valuable
                      lessons about resilience and compassion whilst finding
                      meaning to their experiences of death, loss and grief.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Dependency; emotional, technological and or consumable
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Dealing with unhealthy emotional and consumable dependence
                      can be challenging, but it's crucial to understand that
                      these behaviours aren't signs of weakness. Instead,
                      they're responses to tough emotions and circumstances.
                      Let's view them as opportunities for growth and support.
                    </p>
                    <p>
                      As parents, our role is to create a safe and
                      non-judgmental space where our children and adolescents
                      can openly discuss their emotions and behaviours. We offer
                      empathy and understanding as they navigate these
                      challenges, reassuring them that seeking help and support
                      is absolutely okay.
                    </p>
                    <p>
                      These challenges are part of their unique journey, and our
                      guidance plays a pivotal role in helping them develop
                      healthier ways to cope. Together using techniques like
                      Parent Coaching, Life Readiness Coaching, Naturopathy and
                      Hypnosis we help them embrace the opportunity to cultivate
                      emotional resilience, break free from unhealthy
                      dependencies, and shape a more independent and fulfilling
                      life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Emotional resilience and trauma release
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Each child possesses a remarkable inner strength, a
                      natural capacity to heal and grow. With the right tools
                      this inner strength enables them to process challenging
                      experiences in a healthy and controlled way, ultimately
                      leading to increased resilience and inner security.
                    </p>
                    <p>
                      As parents, our role is to guide children in expressing
                      their feelings, seeking support when needed, and employing
                      healthy coping strategies. This guidance empowers them to
                      take control of their emotional well-being. The process of
                      release becomes a pathway to resilience, allowing them to
                      bounce back from challenges with newfound wisdom and
                      strength.
                    </p>
                    <p>
                      Together using techniques like Parent Coaching,
                      Naturopathy, Somatic Movement, The Goulding Method&reg; of
                      SleepTalk and Hypnosis we can instil confidence and a
                      sense of safety, enabling them to face the world with
                      courage and resilience.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Health, hygiene and well-being
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Helping children embrace the importance of their health
                      and well-being and self care is a remarkable gift, laying
                      the foundation for a lifetime filled with vitality and
                      success.
                    </p>
                    <p>
                      As parents, we comprehend the significance of guiding our
                      children on this path towards self-discovery and
                      well-being. By nurturing their self-worth and sense of
                      empowerment, we guide them towards understanding the value
                      of self-care and health.
                    </p>
                    <p>
                      Together, using techniques like Parent Coaching, Life
                      Readiness Coaching, The Goulding Method&reg; of SleepTalk
                      and Hypnosis we help children take charge of their
                      personal wellness, equipping them with self confidence and
                      pride.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Caring for sickly or elderly parents or relatives
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Caring for sick or elderly loved ones is a noble
                      responsibility, but it can feel overwhelming for everyone
                      in the family, even in the best of times.
                    </p>
                    <p>
                      By fostering resilience and emotional intelligence, we
                      prepare our children to navigate the emotional
                      complexities of caregiving. This preparation enables them
                      to provide empathy, patience, and age appropriate support,
                      making the caregiving journey more meaningful for all
                      involved.
                    </p>
                    <p>
                      Using techniques like Parent Coaching, Life Readiness
                      Coaching, The Goulding Method&reg; of SleepTalk, Hypnosis
                      we can empower them to remain strong, keep a positive
                      outlook, and persevere through tough times, in healthy,
                      congruent ways.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="21">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Regaining independence and confidence after surgeries or
                      health setbacks
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Recovering independence and confidence after surgeries or
                      health setbacks is an incredible journey marked by
                      resilience and determination. Instead of viewing it as a
                      struggle, let's see it as a chance for personal growth and
                      renewed vitality and discovery.
                    </p>
                    <p>
                      Recovery isn't a straightforward path, and setbacks can
                      happen. That's why it's crucial to approach this
                      experience with patience and self-compassion. Encouraging
                      them to find their voice and seek guidance and support
                      when needed empowers them to navigate this process
                      effectively.
                    </p>
                    <p>
                      With guided support using techniques like Parent Coaching,
                      Life Readiness Coaching, Naturopathy, Somatic Movement and
                      The Goulding Method&reg; of SleepTalk we can help them
                      reclaim their life, regain their self-assurance, and
                      embrace the future with renewed vigour and resilience.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="22" onClick={() =>
                window.scrollTo({
                  top: accordionRef.current.offsetTop,
                  behavior: "smooth",
                })
              }>
            <Accordion.Header>
              <h2>Young Adulthood 18 - 35 Years Old</h2>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion flush>
                <Accordion.Item eventKey="23">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Entering the workforce and establishing a career
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Entering the workforce can be an exciting yet daunting
                      journey. The transition from the structured world of
                      education to the dynamic realm of work brings with it a
                      unique set of challenges. You may find yourself grappling
                      with questions like, "Am I on the right career path?" or
                      "How can I excel in my chosen field?"
                    </p>
                    <p>
                      I recognise the importance of finding your footing in the
                      professional world and achieving your career aspirations.
                      Whether it's overcoming interview anxiety, enhancing your
                      communication skills, or boosting your self-confidence, I
                      am here to support you at every step.
                    </p>
                    <p>
                      Working together using methods of Development Coaching,
                      and Hypnosis, I believe you will not only enter the
                      workforce with confidence but also establish a fulfilling
                      and prosperous career.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="24">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Performance enhancement
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      In today's competitive world, the desire to excel and
                      achieve peak performance is a common aspiration,
                      especially for young adults striving to make their mark.
                      However, it's not uncommon to encounter obstacles that
                      hinder optimal performance, such as stress, self-doubt, or
                      difficulty focusing.
                    </p>
                    <p>
                      I understand the importance of unlocking your full
                      potential, whether it's in your career, academics, or
                      personal pursuits. It can feel frustrating, knowing you
                      have more but accessing it is proving to be a little
                      difficult.
                    </p>
                    <p>
                      Together using techniques like Development Coaching,
                      Somatic Movement, Naturopathy and Hypnosis you will break
                      through limitations and achieve the performance levels
                      you've always envisioned.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="25">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Career and goal achievement, explore new goals and find a
                      sense of purpose
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Navigating the path toward career and personal goal
                      achievement is a journey filled with aspirations,
                      challenges, and self-discovery. Young adults often grapple
                      with questions like, "How can I set meaningful goals?" or
                      "What steps can I take to turn my dreams into reality?"
                    </p>
                    <p>
                      I understand that the journey to success is unique for
                      each individual and can be accompanied by self-doubt and
                      setbacks. My coaching sessions provide a structured
                      approach to help you identify your aspirations, set SMART
                      (Specific, Measurable, Achievable, Relevant, Time-bound)
                      goals, and create actionable plans to reach them.
                    </p>
                    <p>
                      Using techniques like Development Coaching, Root Cause
                      Reset and Hypnosis we work on aligning your subconscious
                      mind with your goals. By tapping into the power of the
                      subconscious, we can address limiting beliefs and foster a
                      mindset of success, enhancing your motivation and
                      determination.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="26">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Life transitions such as relocation and studies in higher
                      education.
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Life is a series of transitions, each marked by its unique
                      set of emotions and challenges. Young adults, in
                      particular, face significant life changes that can be both
                      exhilarating and overwhelming. Whether it's the
                      uncertainty of continuing onto higher education or
                      travelling the world or the excitement and stress of
                      relocation, these moments can redefine your sense of self
                      and purpose.
                    </p>
                    <p>
                      I understand that navigating these transitions can be a
                      rollercoaster of emotions. It's natural to have concerns,
                      doubts, and even anxiety about what lies ahead.
                    </p>
                    <p>
                      Life transitions are opportunities for growth,
                      self-discovery, and renewal. Using techniques like
                      Development Coaching and Hypnosis you can embrace change
                      with resilience, optimism, and a sense of adventure.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="27">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Romantic relationships, improve communication skills,
                      resolve conflicts, and address relationship-related
                      stressors
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Navigating romantic relationships can be both thrilling
                      and challenging, especially for young adults who are in
                      the process of forming and maintaining intimate
                      connections. It's natural to encounter communication
                      hurdles, conflicts, and stressors that can strain even the
                      strongest of relationships.
                    </p>
                    <p>
                      I understand the complexities of romantic partnerships,
                      and acknowledge that open and empathetic communication,
                      conflict resolution, and managing relationship-related
                      stressors are essential for cultivating healthy and
                      lasting relationships.
                    </p>
                    <p>
                      By addressing individual and shared concerns using
                      techniques like Relationship Coaching, Root Cause Reset,
                      Somatic Movement and Hypnosis you can achieve emotional
                      balance with the ability to build stronger, more resilient
                      bonds which are aligned with your authentic self, giving
                      you confidence to put up boundaries, give and receive at a
                      synergetic level.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="28">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Divorce or separation, co-parenting and single parenting,
                      introducing your child to a new partner
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Any of these challenges will bring forth a wide range of
                      emotions which demand a delicate balance of understanding
                      and empathy. Amidst the complexities of these transitions,
                      I encourage you to approach each hurdle with a
                      compassionate heart and an open mind. Consider viewing
                      these experiences as invitations to deepen your
                      self-awareness, cultivate resilience, and tap into your
                      inner wisdom.
                    </p>
                    <p>
                      As you embark on this journey of transformation, remember
                      that you are worthy of love, healing, and happiness. With
                      gentleness, kindness, and thoughtful techniques like Root
                      Cause Reset, Development Coaching, Somatic Movement and
                      Hypnosis, I am here to support you every step of the way.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="29">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Motivation and self discipline practices
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Motivation and self-discipline are the cornerstones of
                      success in any endeavour, yet many young adults find
                      themselves struggling with these vital skills. It's common
                      to feel overwhelmed by procrastination, lack of focus, or
                      difficulty in staying committed to goals.
                    </p>
                    <p>
                      I recognize that these skills are the driving force behind
                      personal and professional achievements, and the lack
                      thereof can lead to overwhelm and even feelings of shame.
                      By reprogramming thought patterns and behaviours that are
                      holding you back you are allowing succession toward a
                      motivated, focused, and disciplined approach to your
                      goals.
                    </p>
                    <p>
                      Together using techniques like Development Coaching,
                      Somatic Movement, Naturopathy and Hypnosis you can break
                      free from the cycle of procrastination and uncertainty,
                      and embark on a journey of consistent achievement.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="30">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Gaining financial independence and self discipline
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Financial independence is a significant milestone in the
                      journey to adulthood, and it often goes hand in hand with
                      the need for self-discipline in managing one's finances.
                      You may find yourself facing challenges related to
                      budgeting, saving, and spending habits, which can impact
                      your long-term financial well-being.
                    </p>
                    <p>
                      I understand the importance of mastering these skills as
                      it is essential for building a secure and prosperous
                      future.
                    </p>
                    <p>
                      Using techniques like Development Coaching and Hypnosis we
                      can begin addressing subconscious beliefs and habits
                      related to money, which can help you develop a healthy and
                      responsible relationship with your finances, promoting
                      sound financial decision-making which is congruent with
                      your values.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="31">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Starting a family including fertility coaching, prenatal
                      and antenatal self care
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Starting a family is a profound and life-changing journey,
                      often accompanied by a mix of excitement and
                      uncertainties. As you embark on this path you may
                      encounter challenges related to fertility, pregnancy, and
                      the transition into parenthood.
                    </p>
                    <p>
                      I understand the desire to build a family and the unique
                      considerations that come with it. Although daunting at
                      times your perception of your experience is unique to you
                      and your journey so having support to validate that is
                      important.
                    </p>
                    <p>
                      By using techniques like Family Coaching, Somatic
                      Movement, Naturopathy and Hypnosis we can work on physical
                      and emotional changes and offer unwavering support,
                      encouragement, and practical solutions as you embark on
                      this exciting family-building journey.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="32">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Self esteem, self worth and confidence
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Self-doubt and low self-esteem can hinder your personal
                      and professional development. It can feel excessively
                      isolating and frustrating to overcome past insecurities,
                      conquer imposter syndrome, or seek self-assurance in
                      various aspects of life when trying to do so from a
                      position of absolute.
                    </p>
                    <p>
                      I believe that everyone has the potential to radiate
                      confidence and embrace their worthiness when given the
                      right tools.
                    </p>
                    <p>
                      Using techniques like Development Coaching, Somatic
                      Movement, Naturopathy and Hypnosis we can empower you to
                      embrace your unique, authentic qualities and recognise
                      your inherent worth, unlocking your full potential,
                      building unshakable self-esteem, and cultivating the
                      confidence needed to thrive in every aspect of your life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="33">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Death, loss and grief
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Firstly, I am so sorry you are going through this
                      experience. Grief has a sting like no other. Coping with
                      death and loss can be an emotionally challenging journey,
                      and it's important to recognise the need for empathetic
                      support during these times.
                    </p>
                    <p>
                      I believe that, with the right nurturing guidance,
                      individuals can find their way through grief and emerge
                      stronger, with a renewed sense of purpose, a reborn sense
                      of control and an incredible ability to process emotions.
                    </p>
                    <p>
                      Together using techniques like Development Coaching,
                      Somatic Movement and Hypnosis we can facilitate healing
                      and provide you with tools to cope with grief in a healthy
                      and constructive manner.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="34">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Anxiety, stress, fear and relaxation techniques
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The fast-paced nature of modern life can create
                      overwhelming pressures, leading to mental and emotional
                      challenges which have you questioning your ability to be
                      present enough to face them.
                    </p>
                    <p>
                      By exploring the sources of anxiety, stress, and fear in
                      your life we can offer practical strategies to manage and
                      mitigate these emotions. Whether it's work-related stress,
                      social anxiety, or irrational fears, I believe you are
                      able to regain control and find a sense of calm.
                    </p>
                    <p>
                      Together, using techniques like Development Coaching,
                      Somatic Movement and Hypnosis you can learn to manage
                      anxiety, stress, and fear effectively, allowing you to
                      live with greater confidence and emotional balance.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="35">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Negative thoughts and behaviours
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Negative thoughts and behaviours can be a significant
                      hurdle when striving for personal growth and happiness.
                      These thoughts often manifest as self-doubt,
                      self-criticism, and patterns of behaviour that hinder
                      progress and well-being.
                    </p>
                    <p>
                      I understand the impact of negative thinking on your life
                      and recognize that breaking free from these patterns can
                      feel overwhelmingly impossible to do, however, I do
                      believe that everyone has the potential to change their
                      thought patterns and behaviours for the better when they
                      are taught how to.
                    </p>
                    <p>
                      Using techniques like Development Coaching, Root Cause
                      Reset, Somatic Movement and Hypnosis we can provide you
                      with the tools and strategies to reframe your thinking and
                      shift towards a more positive mindset.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="36">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Dependency; emotional, technological and or consumable
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Self-awareness is the first step towards breaking free
                      from the constraints that dependencies can impose on your
                      journey to self-discovery and empowerment. Just being here
                      means you're taking the first step, the most important
                      one.
                    </p>
                    <p>
                      Be it relying on others for emotional support or seeking
                      comfort in consumable habits such as substances or
                      unhealthy patterns, they all have the potential to impact
                      self-growth and hinder the pursuit of happiness. By
                      loosening the grip of these dependencies, they lose their
                      power, paving the way for positive transformation.
                    </p>
                    <p>
                      Using tools like Development Coaching, Root Cause Reset,
                      Naturopathy, Somatic Movement and Hypnosis you can embrace
                      the opportunity to cultivate emotional resilience, break
                      free from unhealthy dependencies, and shape a more
                      independent and fulfilling life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="37">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Smoking/vaping cessation
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Whether it's today, tomorrow or perhaps by the end of next
                      month, you'll know when you are ready to stop and you also
                      know it will be soon.
                    </p>
                    <p>
                      Quitting is more than just breaking a habit; it's a
                      holistic approach to understanding triggers, managing
                      stress, and redefining your relationship with smoking. By
                      reprogramming ingrained thought patterns and associations
                      with smoking, I help you break free from the physical and
                      psychological hold you feel it has on you.
                    </p>
                    <p>
                      Using techniques like Root Cause Reset, Naturopathy and
                      Hypnosis we'll navigate the challenges, celebrate the
                      victories, and pave the way for a healthier, smoke-free
                      you.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="38">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Focus on health and well-being including memory and
                      cognitive function and quality of sleep
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      I recognise the multifaceted challenges you may be facing
                      in maintaining a healthy lifestyle all round. It can be
                      extremely overwhelming but I commend you for your
                      commitment to yourself, right here in this moment.
                    </p>
                    <p>
                      By tapping into the mind-body connection, we work to
                      alleviate stressors, reframe negative thought patterns,
                      and enhance your mental resilience. By promoting a
                      harmonious balance between mind and body I believe one can
                      achieve optimal well-being.
                    </p>
                    <p>
                      Using techniques like Development Coaching, Root Cause
                      Reset, Somatic Movement, Naturopathy and Hypnosis we can
                      create customised strategies to elevate your overall
                      health and well-being.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="39">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Emotional resilience and trauma release
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Whether you're facing daily stressors, dealing with past
                      traumas, or simply seeking to build emotional resilience,
                      your experiences are valid and true to you.
                    </p>
                    <p>
                      Your emotional well-being is a priority, thus you are
                      provided a safe and supportive space for you to explore
                      and understand the layers of your emotions. Exploring the
                      root cause of your emotional challenges and building inner
                      strength will help you navigate life's ups and downs with
                      greater ease, fostering a resilient mindset and allowing
                      you to reclaim control over your emotions and responses.
                    </p>
                    <p>
                      Using techniques like Development Coaching, Root Cause
                      Reset, Somatic Movement and Hypnosis you can look forward
                      to cultivating emotional strength, liberating yourself
                      from the shackles of trauma, and embracing a life
                      characterised by resilience, empowerment, and emotional
                      well-being.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="40">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Caring for sickly or elderly parents or relatives
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Taking on the responsibility of caring for sickly or
                      elderly parents and relatives is a unique and complex
                      journey. Balancing compassion for your loved ones with
                      personal growth and self-care becomes a delicate but
                      crucial aspect of this caregiving role and I commend you
                      for your depth of commitment knowing the emotional toll it
                      may take.
                    </p>
                    <p>
                      I understand the need for self-care and personal growth,
                      even while fulfilling the responsibilities of caring for
                      others. Through personalised strategies, we explore ways
                      to manage stress, foster resilience, and create a
                      sustainable balance between your caregiving duties and
                      personal aspirations.
                    </p>
                    <p>
                      Together using techniques like Development Coaching, Root
                      Cause Reset, Somatic Movement and Hypnosis, compassion can
                      coexist with your aspirations for a fulfilling and
                      balanced life
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="41">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Regaining independence and confidence after surgeries or
                      health setbacks
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Whether you're regaining physical strength or rebuilding
                      confidence, your journey is unique to you and your desire
                      to regain control and shift the narrative of your
                      experience is commendable.
                    </p>
                    <p>
                      Through personalised strategies, I guide you in setting
                      achievable goals, overcoming setbacks, and fostering
                      resilience. By addressing any lingering doubts or
                      anxieties, we work towards building a positive self-image.
                      This transformative process aids in cultivating not just
                      physical recovery but also a mindset that radiates
                      confidence and empowerment.
                    </p>
                    <p>
                      Using techniques like Development Coaching, Root Cause
                      Reset, Somatic Movement, Naturopathy and Hypnosis you can
                      create a roadmap towards renewed independence and a
                      confidence that extends beyond health challenges.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="42" onClick={() =>
                window.scrollTo({
                  top: accordionRef.current.offsetTop,
                  behavior: "smooth",
                })
              }>
            <Accordion.Header>
              <h2>Adulthood 35 - 65 Years Old</h2>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion flush>
                <Accordion.Item eventKey="44">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Romantic relationships, improve communication skills,
                      resolve conflicts, and address relationship-related
                      stressors
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Navigating the intricate terrain of romantic relationships
                      takes on new dimensions as we mature. Decisions feel
                      bigger, values begin to differ and we feel like time is
                      catching up on us, too quickly.
                    </p>
                    <p>
                      Recognising the evolving landscape of romantic
                      partnerships, I emphasise the significance of fostering
                      open and empathetic communication, adept conflict
                      resolution, and skillful management of
                      relationship-related stressors. These elements remain
                      pivotal for nurturing relationships that not only endure
                      the test of time but continue to flourish.
                    </p>
                    <p>
                      Drawing upon the wealth of experiences accumulated over
                      the years, the application of Relationship Coaching, Root
                      Cause Reset and Hypnosis emerges as an invaluable
                      resource. By addressing individual and shared concerns
                      with these techniques, one can achieve emotional
                      equilibrium. This approach enables the cultivation of
                      stronger, more resilient bonds aligned with one's
                      authentic self, instilling the confidence needed to
                      establish boundaries and engage in reciprocal, synergistic
                      interactions that characterise mature and fulfilling
                      relationships.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="45">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Divorce or separation, co-parenting and single parenting,
                      introducing your child to a new partner
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Any of these challenges will bring forth a wide range of
                      emotions which demand a delicate balance of understanding
                      and empathy. Amidst the complexities of these transitions,
                      I encourage you to approach each hurdle with a
                      compassionate heart and an open mind. Consider viewing
                      these experiences as invitations to deepen your
                      self-awareness, cultivate resilience, and tap into your
                      inner wisdom.
                    </p>
                    <p>
                      As you embark on this journey of transformation, remember
                      that you are worthy of love, healing, and happiness. With
                      gentleness, kindness, and thoughtful techniques like Root
                      Cause Reset, Development Coaching, Somatic Movement and
                      Hypnosis, I am here to support you every step of the way.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="46">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Performance enhancement
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      In the dynamic landscape of today's competitive world, the
                      pursuit of excellence and peak performance takes on a
                      profound significance. As individuals seek to leave a
                      lasting impact, they may encounter hurdles that impede
                      optimal performance, such as stress, self-doubt, or
                      challenges in maintaining focus.
                    </p>
                    <p>
                      Recognising the aspirations of those navigating this stage
                      of life, I emphasise the importance of unlocking one's
                      full potential across career, academics, and personal
                      pursuits. The journey can be challenging, knowing that
                      there is untapped capability waiting to be harnessed.
                    </p>
                    <p>
                      Through the application of techniques like Development
                      Coaching and Hypnosis, individuals can transcend
                      limitations and reach the pinnacle of their envisioned
                      performance levels. This collaborative approach provides
                      the tools and insights needed to break through obstacles,
                      fostering a path toward sustained excellence and
                      accomplishment.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="47">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Motivation and self discipline practices
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Achieving success in any pursuit hinges on the foundations
                      of motivation and self-discipline, challenges encountered
                      by individuals across various life stages. Many grapple
                      with these essential skills, contending with
                      procrastination, a lack of focus, or struggles in
                      maintaining commitment to their goals.
                    </p>
                    <p>
                      Recognising the universal nature of these challenges, I
                      emphasise the pivotal role that motivation and
                      self-discipline play in both personal and professional
                      accomplishments. The absence of these skills can lead to
                      overwhelming feelings and, at times, even a sense of
                      shame. By reshaping thought patterns and behaviours that
                      hinder progress, individuals can pave the way for a
                      motivated, focused, and disciplined approach to their
                      objectives.
                    </p>
                    <p>
                      Through the application of effective techniques like
                      Development Coaching, Somatic Movement, and Hypnosis,
                      individuals can liberate themselves from the cycle of
                      procrastination and uncertainty. This collaborative
                      approach provides the tools and strategies necessary to
                      embark on a transformative journey marked by consistent
                      achievement, irrespective of age or life stage.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="48">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Gaining financial independence and self discipline
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Gaining financial independence is a significant
                      achievement, often tied to the need for self-discipline in
                      managing finances. Challenges related to budgeting,
                      saving, and spending habits can significantly impact
                      long-term financial well-being.
                    </p>
                    <p>
                      In recognising the importance of mastering these financial
                      skills, I want you to know that I understand the
                      significance of building a secure and prosperous future.
                    </p>
                    <p>
                      By employing techniques like Development Coaching, Root
                      Cause Reset and Hypnosis, we can gently address the
                      underlying beliefs and habits related to money. This
                      process aims to help you cultivate a positive and
                      responsible relationship with your finances, facilitating
                      sound decision-making aligned with your values. It's a
                      transformative journey that empowers you to navigate
                      financial challenges with confidence and build a
                      foundation for lasting financial stability and success.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="49">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Career and goal achievement, explore new goals and find a
                      sense of purpose
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Embarking on the path toward career and personal goal
                      achievement involves aspirations, challenges, and
                      self-discovery. Individuals often grapple with questions
                      such as, "How can I set meaningful goals?" or "What steps
                      can I take to turn my dreams into reality?"
                    </p>
                    <p>
                      Recognising that the journey to success is unique for each
                      individual and can be accompanied by self-doubt and
                      setbacks, my coaching sessions provide a structured
                      approach. The focus is on helping you identify
                      aspirations, set SMART (Specific, Measurable, Achievable,
                      Relevant, Time-bound) goals, and create actionable plans
                      to reach them.
                    </p>
                    <p>
                      Utilising techniques like Development Coaching and
                      Hypnosis, our work revolves around aligning your
                      subconscious mind with your goals. By tapping into the
                      power of the subconscious, we can address limiting beliefs
                      and foster a mindset of success, enhancing your motivation
                      and determination without specifying a particular life
                      stage.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="50">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Life transitions such as midlife crisis, empty nest
                      syndrome and relocation
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Life is a series of transitions, each marked by its unique
                      set of emotions and challenges. Whether navigating the
                      complexities of a midlife transition, adjusting to an
                      "empty nest" after children leave home, or adapting to the
                      excitement and stress of relocation, these moments can
                      redefine your sense of self and purpose.
                    </p>
                    <p>
                      Understanding that navigating these transitions can be a
                      rollercoaster of emotions, it's natural to have concerns,
                      doubts, and even anxiety about what lies ahead during
                      these transformative phases.
                    </p>
                    <p>
                      Techniques like Development Coaching, Root Cause Reset,
                      Somatic Movement and Hypnosis empower you to embrace these
                      changes with resilience, optimism, and a sense of
                      adventure, supporting you in navigating the emotional
                      complexities and discovering new possibilities for
                      personal growth and fulfilment.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="51">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Starting a family including fertility nurturing , prenatal
                      and antenatal self care
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Embarking on the journey of starting a family is a
                      profound and life-changing experience, marked by a blend
                      of excitement and uncertainties. This path may present
                      challenges related to fertility, pregnancy, and the
                      transition into parenthood.
                    </p>
                    <p>
                      Recognising the universal desire to build a family and the
                      unique considerations that accompany it, I understand the
                      importance of providing support throughout this
                      transformative process. Though it may seem daunting at
                      times, your perception of this experience is distinct and
                      valuable, underscoring the need for support that validates
                      your unique journey.
                    </p>
                    <p>
                      Through the application of techniques like Family
                      Coaching, Somatic Movement, Naturopathy and Hypnosis, our
                      focus is on addressing both the physical and emotional
                      changes associated with starting a family. This approach
                      aims to offer unwavering support, encouragement, and
                      practical solutions tailored to your individual needs as
                      you navigate this exciting family-building journey.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="52">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Perimenopause and Menopause
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Navigating these intricate phases can be an intensely
                      emotional journey, it's common for people to feel isolated
                      during this phase too. Your experiences are unique, but
                      they are also shared by many. You're not alone, and we can
                      navigate through this. I see you, I hear you and I
                      understand.
                    </p>
                    <p>
                      Embracing change is at the core of my approach. With a
                      focus on holistic well-being, my expertise lies in
                      offering tailored solutions for managing symptoms,
                      enhancing emotional balance, and fostering a positive
                      mindset. Together, we embark on a journey of
                      self-discovery, where empowerment and transformation
                      become the pillars of a fulfilling life beyond any
                      previous limitations.
                    </p>
                    <p>
                      Using techniques like, Root Cause Reset, Development
                      Coaching, Somatic Movement, Hypnosis and Naturopathy, I
                      guide individuals in exploring the depths of their
                      experiences, helping them uncover inner resilience and
                      wisdom.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="53">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Self esteem, self worth and confidence
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Overcoming self-doubt and nurturing a healthy self-esteem
                      is crucial for personal and professional development.
                      Conquering imposter syndrome and seeking self-assurance
                      can feel isolating and frustrating, particularly when
                      starting from a position of uncertainty.
                    </p>
                    <p>
                      I firmly believe that everyone possesses the potential to
                      radiate confidence and recognize their inherent worth with
                      the right tools and support.
                    </p>
                    <p>
                      Utilising techniques like Development Coaching, Root Cause
                      Reset, Somatic Movement, and Hypnosis, our aim is to
                      empower you. Through these methods, you can embrace your
                      unique, authentic qualities, unlocking your full
                      potential, building unshakable self-esteem, and
                      cultivating the confidence necessary to thrive in every
                      aspect of your life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="54">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Death loss and grief
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Firstly, I am so sorry you are going through this
                      experience. Grief has a sting like no other. Coping with
                      death and loss can be an emotionally challenging journey,
                      and it's important to recognise the need for empathetic
                      support during these times.
                    </p>
                    <p>
                      I believe that, with the right nurturing guidance,
                      individuals can find their way through grief and emerge
                      stronger, with a renewed sense of purpose, a reborn sense
                      of control and an incredible ability to process emotions.
                    </p>
                    <p>
                      Together using techniques like Development Coaching,
                      Somatic Movement and Hypnosis we can facilitate healing
                      and provide you with tools to cope with grief in a healthy
                      and constructive manner.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="55">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Anxiety, stress, fear and relaxation techniques
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The rapid pace of modern life often brings overwhelming
                      pressures, resulting in mental and emotional challenges
                      that may leave you questioning your ability to be present
                      and face them head-on.
                    </p>
                    <p>
                      In understanding the sources of anxiety, stress, and fear
                      in your life, we can collaboratively develop practical
                      strategies to manage and mitigate these overwhelming
                      emotions. Whether it's work-related stress, social
                      anxiety, or irrational fears, I believe you have the
                      capacity to regain control and find a profound sense of
                      calm.
                    </p>
                    <p>
                      Together, utilising techniques like Development Coaching,
                      Somatic Movement, and Hypnosis, we can empower you to
                      effectively manage anxiety, stress, and fear. Through
                      these methods, you'll acquire the skills to live with
                      greater confidence and emotional balance, allowing you to
                      navigate the demands of modern life with resilience and
                      calm assurance.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="56">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Negative thoughts and behaviours including imposter
                      syndrome and others
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Negative thoughts and behaviours can pose a significant
                      obstacle in the pursuit of personal growth and happiness.
                      These thoughts often materialise as self-doubt,
                      self-criticism, and behavioural patterns that impede
                      progress and well-being.
                    </p>
                    <p>
                      I comprehend the profound impact of negative thinking on
                      your life and acknowledge that breaking free from these
                      patterns may feel overwhelmingly challenging. However, I
                      firmly believe that everyone possesses the potential to
                      change their thought patterns and behaviours for the
                      better when equipped with the right tools.
                    </p>
                    <p>
                      Through the application of techniques like Development
                      Coaching, Root Cause Reset, Somatic Movement, and
                      Hypnosis, we can furnish you with the tools and strategies
                      necessary to reframe your thinking and transition toward a
                      more positive mindset. This collaborative approach aims to
                      empower you to navigate away from negative thought
                      patterns, fostering personal growth and cultivating a
                      happier, more fulfilling life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="57">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Dependency either emotional and or consumable
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Self-awareness serves as the initial stride toward
                      liberating yourself from the constraints that dependencies
                      may impose on your journey to self-discovery and
                      empowerment. Your presence here signifies the crucial
                      first step, the most pivotal one.
                    </p>
                    <p>
                      Whether it involves relying on others for emotional
                      support or seeking comfort in consumable habits, such as
                      substances or unhealthy patterns, these dependencies
                      possess the potential to impact self-growth and impede the
                      pursuit of happiness. By loosening their grip, these
                      dependencies lose their power, creating a path for
                      positive transformation.
                    </p>
                    <p>
                      Through the utilisation of techniques like Development
                      Coaching, Root Cause Reset, Naturopathy, Somatic Movement,
                      and Hypnosis, you have the opportunity to embrace the
                      cultivation of emotional resilience. This process enables
                      you to break free from consumable dependencies, fostering
                      a more independent and fulfilling life. Together, we can
                      navigate this journey towards empowerment, promoting
                      self-discovery and resilience in the pursuit of lasting
                      happiness.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="58">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Smoking/ Vaping cessation
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Whether it's today, tomorrow, or perhaps by the end of
                      next month, you'll recognize when you are ready to stop,
                      and that moment is approaching. Quitting is more than just
                      breaking a habit; it's a holistic approach that involves
                      understanding triggers, managing stress, and redefining
                      your relationship with the habit.
                    </p>
                    <p>
                      Quitting is more than just breaking a habit; it's a
                      holistic approach to understanding triggers, managing
                      stress, and redefining your relationship with the habit.
                      By reprogramming ingrained thought patterns and
                      associations with the habit, I help you break free from
                      the physical and psychological hold you feel it has on
                      you.
                    </p>
                    <p>
                      Using techniques like Root Cause Reset, Naturopathy and
                      Hypnosis we'll navigate the challenges, celebrate the
                      victories, and pave the way for a healthier you.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="59">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Focus on health and well-being including memory and
                      cognitive function and quality of sleep
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      I acknowledge the multifaceted challenges you may be
                      encountering in the pursuit of maintaining a healthy
                      lifestyle. It can indeed be overwhelming, and I commend
                      your commitment to yourself, evident in this very moment.
                    </p>
                    <p>
                      By delving into the intricate connection between the mind
                      and body, our aim is to alleviate stressors, reframe
                      negative thought patterns, and enhance your mental
                      resilience. I firmly believe that by fostering a
                      harmonious balance between mind and body, one can achieve
                      optimal well-being.
                    </p>
                    <p>
                      Through the application of techniques such as Development
                      Coaching, Root Cause Reset, Naturopathy, Somatic Movement,
                      and Hypnosis, we can collaboratively create customised
                      strategies to elevate your overall health and well-being.
                      This holistic approach addresses various facets of your
                      lifestyle, promoting positive change and supporting your
                      journey toward a healthier, more balanced life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="60">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Caring for sickly or elderly parents or relatives
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Undertaking the responsibility of caring for sickly or
                      elderly parents and relatives is a unique and complex
                      journey. Balancing compassion for your loved ones with
                      personal growth and self-care becomes a delicate yet
                      crucial aspect of this caregiving role, and I commend you
                      for your depth of commitment, recognizing the emotional
                      toll it may take.
                    </p>
                    <p>
                      I understand the imperative need for self-care and
                      personal growth even while fulfilling the responsibilities
                      of caring for others. Through personalised strategies, our
                      aim is to explore ways to manage stress, foster
                      resilience, and create a sustainable balance between your
                      caregiving duties and personal aspirations.
                    </p>
                    <p>
                      Utilising techniques like Family Coaching, Root Cause
                      Reset, Somatic Movement, and Hypnosis, we can work
                      together to ensure that compassion coexists with your
                      aspirations for a fulfilling and balanced life. This
                      collaborative approach addresses the complexities of
                      caregiving, supporting you in navigating the challenges
                      while maintaining your well-being and personal growth.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="61">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Negative thoughts and behaviours leading to despondency
                      and discouragement
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Negative thoughts and behaviours can pose a significant
                      obstacle when striving for personal growth and happiness.
                      These thoughts often materialise as self-doubt,
                      self-criticism, and behavioural patterns that impede
                      progress and well-being.
                    </p>
                    <p>
                      I comprehend the profound impact of negative thinking on
                      your life and acknowledge that breaking free from these
                      patterns may feel overwhelmingly challenging. However, I
                      firmly believe that everyone possesses the potential to
                      change their thought patterns and behaviours for the
                      better when equipped with the right tools.
                    </p>
                    <p>
                      Through the application of techniques like Development
                      Coaching, Root Cause Reset, Naturopathy, Somatic Movement,
                      and Hypnosis, we can furnish you with the tools and
                      strategies necessary to reframe your thinking and
                      transition toward a more positive mindset. This
                      collaborative approach aims to empower you to navigate
                      away from negative thought patterns, fostering personal
                      growth and cultivating a happier, more fulfilling life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="62">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Emotional resilience and trauma release
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Whether you're facing daily stressors, dealing with past
                      traumas, or simply seeking to build emotional resilience,
                      your experiences are valid and uniquely true to you. Your
                      emotional well-being is a priority, and thus, you are
                      provided with a safe and supportive space to explore and
                      understand the layers of your emotions.
                    </p>
                    <p>
                      Exploring the root cause of your emotional challenges and
                      building inner strength will empower you to navigate
                      life's ups and downs with greater ease. This process
                      fosters a resilient mindset, allowing you to reclaim
                      control over your emotions and responses.
                    </p>
                    <p>
                      Utilising techniques like Development Coaching, Root Cause
                      Reset, Somatic Movement, and Hypnosis, you can anticipate
                      cultivating emotional strength. This approach liberates
                      you from the shackles of trauma, paving the way for a life
                      characterised by resilience, empowerment, and overall
                      emotional well-being.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="63">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Regaining independence and confidence after surgeries or
                      health setbacks
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Whether you're on the path to regaining physical strength
                      or rebuilding confidence, your journey is a unique
                      expression of your determination to regain control, and
                      your commitment to shifting the narrative of your
                      experience is truly commendable.
                    </p>
                    <p>
                      Through personalised strategies, I guide you in setting
                      achievable goals, overcoming setbacks, and fostering
                      resilience. Addressing any lingering doubts or anxieties,
                      we work collaboratively towards building a positive
                      self-image. This transformative process goes beyond
                      physical recovery, cultivating a mindset that radiates
                      confidence and empowerment.
                    </p>
                    <p>
                      Utilising techniques like Development Coaching, Root Cause
                      Reset, Naturopathy, Somatic Movement, and Hypnosis, you
                      can create a roadmap towards renewed independence and a
                      confidence that extends beyond health challenges. This
                      holistic approach supports your journey, not only in
                      physical recovery but also in fostering enduring mental
                      strength and a positive self-perception.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="64">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Burnout and Imposter Syndrome
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Navigating the challenges of burnout and imposter syndrome
                      can feel like a constant battle in your journey towards
                      personal and professional fulfilment. Recognising the
                      signs of burnout and the insidious grip of imposter
                      syndrome is crucial for reclaiming balance and well-being.
                    </p>
                    <p>
                      Whether it's the relentless demands of work, the
                      complexities of relationships, or the pressure of pursuing
                      personal aspirations, your struggles are acknowledged and
                      understood. Our approach delves into personalised
                      strategies to not only address the underlying causes of
                      burnout but also to confront the debilitating effects of
                      imposter syndrome.
                    </p>
                    <p>
                      Through tailored techniques such as Development Coaching,
                      Root Cause Reset, Somatic Movement, Naturopathy and
                      Hypnosis, we embark on a journey to rewire thought
                      patterns, foster relaxation, and facilitate a profound
                      mental reset. Together, we shift the focus toward
                      nurturing your authentic self and guide you toward a
                      lifestyle that is not only healthier but also more
                      sustainable in the long run.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="65" onClick={() =>
                window.scrollTo({
                  top: accordionRef.current.offsetTop,
                  behavior: "smooth",
                })
              }>
            <Accordion.Header>
              <h2>Late Adulthood 65 + Years Old</h2>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion flush>
                <Accordion.Item eventKey="66">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">Retirement</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Retirement marks a beautiful transition, a phase to savour
                      the fruits of your labour and embark on a journey of
                      self-discovery.
                    </p>
                    <p>
                      I understand that this shift can stir a mix of
                      emotions—excitement, uncertainty, or even a touch of
                      nostalgia. Embrace this change with an open heart,
                      recognising it as an opportunity to redefine your purpose.
                    </p>
                    <p>
                      Retirement isn't just about financial planning; it's about
                      crafting a fulfilling narrative for the chapters ahead.
                    </p>
                    <p>
                      Through gentle reflection and positive reframing and using
                      techniques like Development Coaching and Hypnosis, one can
                      explore the limitless possibilities retirement offers.
                      From pursuing neglected interests to nurturing meaningful
                      relationships, your journey is an open road. Together, we
                      can navigate this transition, turning it into a canvas of
                      endless potential.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="67">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Grandparenting
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Welcome to the heartwarming chapter of grandparenting! I
                      understand the profound joy and wisdom that this role
                      brings. It's a unique opportunity to weave the threads of
                      love and experience into the tapestry of your family's
                      legacy.
                    </p>
                    <p>
                      Grandparenting is a chance to share the stories that
                      shaped you, creating bridges between generations. Your
                      presence holds the power to inspire, nurture, and create
                      lasting memories. Through a lens of positivity, let's
                      explore the delightful nuances of this role - from the
                      laughter-filled gatherings to the quiet moments of wisdom
                      shared.
                    </p>
                    <p>
                      Using techniques like Family Coaching and Hypnosis you can
                      embrace the beauty of being a guiding light, offering
                      support and unconditional love in your most authentic way.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="68">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Self-Esteem and Aging
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Ageing is not a journey of diminishing self-worth but an
                      evolution into the masterpiece of your true self. I
                      understand the significance of nurturing self-esteem
                      through every life stage, especially as the years unfold.
                    </p>
                    <p>
                      Picture your life as a book, each chapter revealing the
                      wisdom and resilience gained. Embrace the lines and
                      wrinkles as a map of your unique experiences, a testament
                      to your strength. Self-esteem, far from fading, has the
                      potential to flourish with the depth of your character.
                    </p>
                    <p>
                      Together, using techniques like Root Cause Reset,
                      Development Coaching and Hypnosis, let's explore the
                      empowering narratives that reinforce your timeless worth
                      and radiate confidence in the person you are becoming.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="69">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Pursuing hobbies and travel
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Embarking on the journey of pursuing hobbies and travel is
                      a delightful expedition into self-discovery and adventure
                      and I recognise the profound impact these experiences can
                      have on your well-being.
                    </p>
                    <p>
                      Whether it's rediscovering a forgotten passion or
                      exploring new interests or finally getting to that travel
                      ‘bucket-list', each pursuit is a step towards a more
                      fulfilled and enriched existence, offering lessons in
                      resilience, and appreciation. Through positive
                      exploration, we can uncover the transformative power of
                      these experiences, fostering personal growth and creating
                      a tapestry of cherished memories.
                    </p>
                    <p>
                      Together, using techniques like Development Coaching and
                      Hypnosis let's cultivate a mindset that sees hobbies and
                      travel not just as activities but as essential elements in
                      the masterpiece of your life, contributing to a more
                      vibrant and joyful existence.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="70">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Focus on health and well-being including memory and
                      cognitive function and quality of sleep
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Your journey toward optimal health is a holistic
                      exploration, weaving together the threads of physical
                      vitality and mental resilience. Prioritising your
                      well-being involves cherishing the remarkable connection
                      between a healthy body and a vibrant mind.
                    </p>
                    <p>
                      Embrace daily habits that not only nourish your physical
                      health but also cultivate cognitive vitality. Picture your
                      mind as a garden, each positive choice a seed that
                      blossoms into enhanced memory, focus, and overall
                      cognitive function. Equally vital is the role of quality
                      sleep in this symphony of well-being. Sleep is not just a
                      necessity; it's a rejuvenating journey that nurtures your
                      body and mind.
                    </p>
                    <p>
                      Together, using techniques like Root Cause Reset, Somatic
                      Movement, Naturopathy and Hypnosis, let's weave a tapestry
                      of well-being that celebrates the harmony between a
                      healthy body, a sharp mind, and the rejuvenating power of
                      sleep.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="71">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Death loss and grief
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      In life's intricate tapestry, the threads of death, loss,
                      and grief are poignant chapters that shape our emotional
                      landscape.
                    </p>
                    <p>
                      Death, though inevitable, need not cast a shadow; it can
                      be a reminder to cherish the moments we have and celebrate
                      the impact of those who have touched our lives. Loss, in
                      its transformative essence, opens space for growth,
                      empathy, and a deeper understanding of our own resilience.
                    </p>
                    <p>
                      Grief, a natural response to loss, is a testament to the
                      depth of our connections. In the midst of sorrow, let's
                      explore positive ways to navigate this emotional journey –
                      embracing memories, fostering self-compassion, and finding
                      solace in the beauty of shared experiences.
                    </p>
                    <p>
                      Together, using techniques like Root Cause Reset, Somatic
                      Movement and Hypnosis, we'll honour the healing power of
                      time, creating a narrative that acknowledges the pain
                      while weaving threads of hope, resilience, and a renewed
                      appreciation for the precious gift of life.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="72">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Mortality salience looking at life review and finding
                      fulfilment
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Mortality salience, the awareness of our finite existence,
                      can be a catalyst for profound self-reflection and the
                      pursuit of true fulfilment. I understand the
                      transformative power embedded in this contemplation.
                    </p>
                    <p>
                      Consider life review not as a reckoning with time slipping
                      away, but as an opportunity to illuminate the meaningful
                      chapters of your journey. What brings you joy, purpose,
                      and a sense of accomplishment? Let's delve into these
                      reflections, uncovering the rich tapestry of experiences
                      that define your unique story.
                    </p>
                    <p>
                      Finding fulfilment in the face of mortality is a noble
                      endeavour. It involves aligning your actions with your
                      deepest values and passions.
                    </p>
                    <p>
                      Together, using techniques like Root Cause Reset,
                      Development Coaching and Hypnosis, we can explore the
                      positive steps you can take to enhance your daily life,
                      creating a narrative that resonates with authenticity and
                      satisfaction, unlocking the door to a life of purpose,
                      growth, connection, and the creation of a truly meaningful
                      existence.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="73">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Explore new goals and find a sense of purpose
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Embarking on a journey to explore new goals is an
                      exhilarating invitation to rediscover your sense of
                      purpose. As you embrace the journey of exploring new
                      goals, remember that each step forward is a celebration of
                      your potential.
                    </p>
                    <p>
                      Imagine your goals as stepping stones towards a more
                      purposeful life—a chance to align your actions with your
                      deepest values. Every goal is an opportunity for growth, a
                      canvas upon which you can paint the vibrant picture of
                      your aspirations. Through positive reflection and
                      intentional planning, we can set the stage for a life that
                      resonates with purpose and meaning.
                    </p>
                    <p>
                      Together, using techniques like Development Coaching and
                      Hypnosis let's explore the passions that ignite your
                      spirit and the accomplishments that bring lasting
                      fulfilment, uncovering the pathways that lead to a more
                      purpose-driven and fulfilling existence.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="74">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Dependency, emotional and or consumable
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Understanding the dynamics of dependency, be it emotional
                      or consumable, is an essential step toward cultivating
                      emotional resilience. By showing interest in change you
                      have already taken the first, pivotal step.
                    </p>
                    <p>
                      By exploring the roots of your emotional connections and
                      uncovering the positive aspects that enhance your
                      well-being you can foster a healthy sense of self.
                      Emotional dependency can be seen as an opportunity to
                      strengthen your inner foundation. Let's explore Similarly,
                      when it comes to consumables, I approach the concept with
                      a mindful perspective. Dependency on certain substances or
                      habits can be reframed as an opportunity for positive
                      change.
                    </p>
                    <p>
                      Together, using techniques like Root Cause Reset,
                      Development Coaching, Somatic Movement and Hypnosis, we
                      can explore healthier alternatives and create a roadmap
                      towards a more balanced and fulfilling lifestyle thus,
                      empowering yourself to navigate life's challenges with
                      grace and authenticity.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="75">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Emotional resilience and trauma release
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Emotional resilience is the bedrock upon which we build
                      our capacity to navigate life's challenges, especially
                      when it comes to trauma.
                    </p>
                    <p>
                      Trauma release is a journey towards healing and reclaiming
                      your emotional well-being. Together, let's explore gentle
                      and empowering techniques to navigate the intricate
                      pathways of trauma, allowing for the release of emotional
                      burdens. Picture this process as a gradual unfolding,
                      creating space for newfound strength and resilience to
                      take root.
                    </p>
                    <p>
                      In the realm of emotional resilience, we'll focus on
                      nurturing the qualities that empower you to bounce back
                      from adversity. Through positive affirmations, mindfulness
                      practices, and tailored strategies, we can weave a
                      tapestry of resilience that supports you on your path to
                      emotional well-being.
                    </p>
                    <p>
                      By crafting a narrative of healing, strength, and
                      self-compassion and using techniques like Root Cause
                      Reset, Somatic Movement, Development Coaching and
                      Hypnosis, you can navigate this path with care, fostering
                      a sense of empowerment and renewal.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="76">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Life transitions such as later-life crisis, empty nest
                      syndrome and relocation
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Transitions are the bridges between chapters in your
                      life's narrative and change, a catalyst for
                      transformation. Rather than fearing the unknown, let's
                      approach transitions with a sense of curiosity and
                      anticipation.
                    </p>
                    <p>
                      In the face of life changes, building resilience becomes
                      key. We'll focus on nurturing the qualities that empower
                      you to bounce back from challenges, fostering emotional
                      strength, and maintaining a sense of balance. Through
                      positive reinforcement and personalised strategies, we'll
                      fortify your foundation for well-being.
                    </p>
                    <p>
                      Using techniques like Root Cause Reset, Development
                      Coaching, Somatic Movement and Hypnosis, you'll cultivate
                      a mindset that welcomes change as an integral part of the
                      journey, unfolding new possibilities, and propelling you
                      toward personal growth.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="77">
                  <Accordion.Header>
                    <div className="accordion-trigger-nested">
                      Regaining independence and confidence after surgeries or
                      health setbacks
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Facing surgeries or health setbacks may momentarily alter
                      your path, but it's also a powerful opportunity for
                      renewal and rediscovery, these are all a testament to your
                      resilience. Remember that every step forward is a triumph.
                    </p>
                    <p>
                      By focusing on the positive aspects of overcoming
                      obstacles, emphasising the courage and determination that
                      define your journey and reframing setbacks as stepping
                      stones, you can rebuild your confidence and embrace a
                      renewed sense of purpose.
                    </p>
                    <p>
                      Together, using techniques like Root Cause Reset, Somatic
                      Movement, Naturopathy and Hypnosis, let's embark on a
                      journey of self-acceptance, emphasising the strengths and
                      capabilities that define you. We'll cultivate a mindset
                      that fosters confidence, empowering you to face each day
                      with renewed vigour.
                    </p>

                    <div className="content-row-l">
                      <div>
                        <BookNow></BookNow>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="contact-section">
        <div>
          <div>
            <h1>Get in touch</h1>
            <p>Drop me a line or two if you want to work together!</p>
            <p>
              +34-711-060-295
              <br />
              <a
                href="mailto:me@tyra-lee.com"
                target="_blank"
                rel="noreferrer"
                title="send an Email to Tyra-Lee"
              >
                me@tyra-lee.com
              </a>
            </p>
            <div className="social-media">
              <a
                href="https://www.facebook.com/profile.php?id=100090122096259"
                title="Tyra-Lee on Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon></FacebookIcon>
              </a>
              <a
                href="http://www.instagram.com/itsme_tyralee"
                title="Tyra-Lee on Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon></InstagramIcon>
              </a>
              <a
                href="https://www.linkedin.com/in/tyra-lee-the-development-coach-17546127/"
                title="Tyra-Lee on LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinIcon></LinkedinIcon>
              </a>
              <a href="/" title="Tyra-Lee on Spotify">
                <SpotifyIcon></SpotifyIcon>
              </a>
              <a href="/" title="Tyra-Lee on YouTube">
                <YoutTubeIcon></YoutTubeIcon>
              </a>
            </div>
            <div className="contact-book-wrapper">
              <BookNow></BookNow>
            </div>
          </div>
          <Link
            to="/terms-and-conditions"
            className="terms-link"
            onClick={() =>
              window.scrollTo({
                top: topRef.current.offsetTop,
                behavior: "instant",
              })
            }
          >
            Terms & Conditions & Disclaimer
          </Link>
        </div>
        <div>
          <img src={getInTouch} alt="Tyra-Lee" />
        </div>
      </div>
    </div>
  );
}

export default Home;
